import Link from '../entities/link';

class LinkService {


    constructor() {
        this.links = [];
    }



    addLink(name, url) {
        let link = new Link(name, url);
        this.links.push(link);
    }

    getLinks() {
        return this.links;
    }

    //generate 100s of links from existing links which takes value parameter which defaults to 10 and randomly generates 10 links

    multiplyLinks(value = 10) {
        const pattern = [8, 3, 2, 5, 1, 7, 4, 0, 6]; // Define the pattern here, representing the index order of the links
        const linkCount = this.links.length;

        for (let i = 0; i < value; i++) {
            pattern.forEach((index) => {
                const link = this.links[index];
                this.addLink(link.name, link.url, 0);
            });
        }
    }

}

export default LinkService;