import React from "react";
import "./bubble.css";

const Bubble = (props) => {
  const backgroundImage = require(`../../../../../assets/backgrounds/${props.name}.png`);
  const onClick = ()=> {
    window.open(props.url, "_blank");
  }
  return (
    <div
      onClick={onClick}
      aria-hidden="true"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "contain",
      }}
      className={"bubble"}
    >
      <img
        src={require(`../../../../../assets/icons/${props.name}.svg`)}
        className={"img"}
      />
    </div>
  );
};

export default Bubble;
