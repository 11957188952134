import React from "react";
import "./home.css";
import Links from "./links/links";

const Home = () => {
  return (
    <div className="home">
      <div className="home__header">
        {/* <h1>KARUN SHRESTHA</h1>
        <p>digital creator</p> */}
        <Links/>
      </div>
    </div>
  );
};

export default Home;