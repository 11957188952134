import React from 'react';
import './App.css';
import Home from './ui/pages/home';

const App = () => {
    return (
        <div className='App'>
            <Home/>
        </div>
    );
}

export default App;