import React from "react";
import "./links.css";
import Bubbles from "./bubbles/bubbles";


const Links = () => {
  return (
    <div>
      {/* <h1>Find me on</h1> */}
      <Bubbles />
    </div>
  );
};

export default Links;
