import { React, useEffect } from "react";
import Bubble from "./bubble/bubble";
import BubbleUI from "react-bubble-ui";
import LinkService from "../../../../services/linkservice";
import "./bubbles.css";

import useWindowDimensions from "../../../../utils";

const linkService = new LinkService();

const getBubbles = () => {
linkService.addLink('facebook', 'https://www.facebook.com/karskit');
linkService.addLink('instagram', 'https://instagram.com/karunstha');
linkService.addLink('linkedin', 'https://linkedin.com/in/karunstha');
linkService.addLink('snapchat', 'https://snapchat.com/add/karunstha');
linkService.addLink('tiktok', 'https://tiktok.com/@karunstha');
linkService.addLink('twitter', 'https://twitter.com/karunstha');
linkService.addLink('vsco', 'https://vsco.co/karunstha/gallery');
linkService.addLink('www', 'https://karunshrestha.com.np');
linkService.addLink('youtube', 'https://youtube.com/karunstha');
  linkService.multiplyLinks(63);
  const links = linkService.getLinks();

  return links.map((link, i) => {
    return <Bubble {...link} key={i} />;
  });
};

const stockBubbles = getBubbles();

const Bubbles = () => {
  const { height, width } = useWindowDimensions();
  var size = width / 3.8;
  if (height < width) {
    size = height / 4;
  }

  const links = linkService.getLinks();
  const columns = Math.ceil(Math.sqrt(links.length));

  // console.log(size.toString());
  // console.log(height.toString());
  // console.log(width.toString());

  if (height < width) {
    var fWidth = width / 3.25;
    var fWidth_temp = width / 4;
    var bubbleWidth = width / 4;
    var bubbleHeight = (height / 2 - fWidth_temp) / 2;
  } else {
    var fWidth = height / 3.25;
    var fWidth_temp = height / 5;
    var bubbleHeight = height / 4;
    var bubbleWidth = (width / 2 - fWidth_temp) / 2;
  }
  const options = {
    size: size,
    minSize: 0,
    gutter: size / 9,
    provideProps: true,
    numCols: columns,
    fringeWidth: fWidth,
    xRadius: bubbleWidth,
    yRadius: bubbleHeight,
    cornerRadius: 10,
    showGuides: false,
    compact: true,
    gravitation: 5,
  };

  useEffect(() => {
    const bubbles = document.querySelector("._2MD0k");
    const dragspeed = 2;
    let isDown = false;
    let startX;
    let startY;
    let scrollLeft;
    let scrollTop;

    bubbles.addEventListener("mousedown", (e) => {
      isDown = true;
      bubbles.classList.add("active");
      startX = e.pageX - bubbles.offsetLeft;
      startY = e.pageY - bubbles.offsetTop;
      scrollLeft = bubbles.scrollLeft;
      scrollTop = bubbles.scrollTop;
    });
    bubbles.addEventListener("mouseleave", () => {
      isDown = false;
      bubbles.classList.remove("active");
    });
    bubbles.addEventListener("mouseup", () => {
      isDown = false;
      bubbles.classList.remove("active");
    });
    bubbles.addEventListener("mousemove", (e) => {
      if (!isDown) return;
      e.preventDefault();
      const x = e.pageX - bubbles.offsetLeft;
      const y = e.pageY - bubbles.offsetTop;
      const walk = (x - startX) * dragspeed;
      const topwalk = (y - startY) * dragspeed;
      bubbles.scrollLeft = scrollLeft - walk;
      bubbles.scrollTop = scrollTop - topwalk;
    });
  }, []);

  return (
    <div>
      <BubbleUI className="bubbleUI" options={options}>
        {stockBubbles}
      </BubbleUI>
    </div>
  );
};

export default Bubbles;

